<template>
  <div>
    <div class="check-step bg-white d-flex shadow-sm">
      <div class="item">
        <span>01.</span>
        <span>確認商品</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item active">
        <span>02.</span>
        <span>取貨方式</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item finish">
        <span>03.</span>
        <span>金流付款</span>
        <span class="material-icons-outlined"></span>
      </div>
    </div>

    <div class="check-way">
      <br />
      <div role="tabpanel">
        <!-- List group -->
        <label style="padding: 0 10px">
          請選擇取貨方式：
          <span style="color: red">(必填)</span>
        </label>
        <div class="check-way-list list-group" id="checkWayList" role="tablist">
          <a
            class="list-group-item list-group-item-action"
            :class="{'active': state.useCheckoutType === 'instore'}"
            data-toggle="list"
            role="tab"
            @click="handleTabClick('instore')"
            v-if="shop_shipping.instore.status === 1"
          >自取</a>
          <a
            class="list-group-item list-group-item-action"
            :class="{'active': state.useCheckoutType === 'delivery'}"
            data-toggle="list"
            role="tab"
            @click="handleTabClick('delivery');
              state.tabDelivery = true;
            "
            v-if="shop_shipping.delivery.status === 1"
          >宅配</a>
        </div>

        <!-- Tab panes -->
        <div class="check-way-content tab-content">
          <div class="container" v-if="state.shippingDescription">
            <div class="d-block my-2 border-bottom">
              <p class="mb-0 small text-muted">店家備註：</p>
              <!--
              <p>{{ state.shippingDescription }}</p>
              -->
              <p v-html="state.shippingDescription"></p>
            </div>
          </div>
          <!--自取-->
          <div
            class="tab-pane py-2 active"
            id="inStore"
            role="tabpanel"
            v-show="state.useCheckoutType === 'instore'"
          >
            <div class="form-group container">
              <label>
                取貨日期：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <!--
                  <time-select
                    v-model="state.usePickDate"
                    :options="state.optionsPickDateObjects"
                  />
                  -->
                  <!--
                  <el-date-picker input-class="form-control" v-model="value1" type="date" placeholder="請選擇時間">
                  -->
                  <el-date-picker
                    input-class="form-control"
                    v-model="state.usePickDate"
                    type="date"
                    placeholder="請選擇日期"
                    size="large"
                    :editable="false"
                    :disabled-date="disabledDate"
                  ></el-date-picker>
                  <!-- :picker-options="pickerOptions" -->
                </div>
              </div>
            </div>
            <div class="form-group container">
              <label for="inStoreBuyerName">
                訂購人姓名：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerName"
                    type="text"
                    class="form-control"
                    id="inStoreBuyerName"
                    placeholder="請輸入訂購人姓名..."
                  />
                </div>
              </div>
              <label for="inStoreBuyerPhone">
                訂購人電話：
                <span style="color: red">(必填，現場請憑電話號碼取貨)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerPhone"
                    type="tel"
                    class="form-control"
                    id="inStoreBuyerPhone"
                    placeholder="請輸入訂購人電話..."
                  />
                </div>
              </div>
              <label for="inStoreComment">備註：</label>
              <div class="row row-cols-1">
                <div class="col">
                  <textarea
                    v-model="state.useComment"
                    class="form-control"
                    id="inStoreComment"
                    rows="3"
                    name="inStoreComment"
                    placeholder="請輸入備註事項..."
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="form-group container">
              <div class="row text-right font-weight-bold" style="font-size: medium">
                <div class="col-12">
                  <span>小計：</span>
                  <span class="text-main">{{ cartQuantity }}</span>
                  <span>{{ $t("ORDER.TOTAL.GOODS_UNIT") }}</span>
                  <span style="margin: 10px; color: darkgray">｜</span>
                  <span>{{ $t("ORDER.TOTAL.MONEY") }}：</span>
                  <span class="text-main">$ {{ formatMoney(cartTotal) }}</span>
                  <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
                </div>
                <div class="col-12" v-if="state.useCheckoutType != 3">
                  <span>{{ state.shippingTypeName }}運費：</span>
                  <span class="text-main">${{ state.shippingAmount }}</span>
                  <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
                  <span
                    style="color: #d3d3d3; font-width: normal"
                    v-if="state.shippingFreeDescription > 0"
                  >
                    <br />
                    (滿{{ state.shippingFreeDescription }}元免運費)
                  </span>
                </div>
                <div class="col-12">
                  <span>總計：</span>
                  <span class="text-main">
                    $
                    {{
                    formatMoney(
                    parseInt(cartTotal, 10) +
                    parseInt(state.shippingAmount, 10)
                    )
                    }}
                  </span>
                  <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
                </div>
              </div>
            </div>
          </div>
          <!--宅配-->
          <div
            class="tab-pane py-2 active"
            id="deliver"
            role="tabpanel"
            v-show="state.useCheckoutType === 'delivery'"
          >
            <div class="form-group container">
              <label>
                到貨日期：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <!--
                  <time-select
                    v-model="state.usePickDate"
                    :options="state.optionsPickDateObjects"
                  />
                  -->
                  <el-date-picker
                    input-class="form-control"
                    v-model="state.usePickDate"
                    type="date"
                    placeholder="請選擇日期"
                    size="large"
                    :editable="false"
                    :disabled-date="disabledDate"
                  ></el-date-picker>
                  <!--
                  :picker-options="pickerOptions"-->
                </div>
              </div>
            </div>
            <div class="form-group container">
              <label for="buyerNameDelivery">
                訂購人姓名：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerName"
                    type="text"
                    class="form-control"
                    id="buyerNameDelivery"
                    placeholder="請輸入訂購人姓名..."
                  />
                </div>
              </div>
              <label for="buyerPhoneDelivery">
                訂購人電話：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerPhone"
                    type="tel"
                    class="form-control"
                    id="buyerPhoneDelivery"
                    placeholder="請輸入訂購人電話..."
                  />
                </div>
              </div>
              <label for="buyerAddressDelivery">
                {{ code === '1657785018' ? '舊家' : '訂購人' }}地址：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row mb-2">
                <cascader-cites
                  :cityId="state.useBuyerCityId"
                  :cityareaId="state.useBuyerCityareaId"
                  @change="setProfileSelect"
                ></cascader-cites>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerAddress"
                    type="text"
                    class="form-control"
                    id="buyerAddressDelivery"
                    :placeholder="`請輸入${ code === '1657785018' ? '舊家' : '訂購人' }地址...`"
                  />
                </div>
              </div>
              <hr />
            </div>
            <div class="form-group container">
              <label for="receiverNameDelivery">寄件地址：</label>
              <div class="row row-cols-2">
                <div class="col">
                  <label
                    for="options0"
                    class="btn btn-block"
                    style="border: 1px solid #dee2e6"
                    @click="state.showReceiver = false"
                  >
                    <input id="options0" type="radio" name="options" value="same" checked="checked" />
                    同上
                  </label>
                </div>
                <div class="col">
                  <label
                    for="options1"
                    class="btn btn-block"
                    style="border: 1px solid #dee2e6"
                    @click="state.showReceiver = true"
                  >
                    <input id="options1" type="radio" name="options" value="other" />
                    其他地址
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group container" v-show="state.showReceiver">
              <label for="receiverNameDelivery">
                收件人姓名：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useReceiverName"
                    type="text"
                    class="form-control"
                    id="receiverNameDelivery"
                    placeholder="請輸入收件人..."
                  />
                </div>
              </div>
              <label for="receiverPhoneDelivery">
                收件人電話：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useReceiverPhone"
                    type="tel"
                    class="form-control"
                    id="receiverPhoneDelivery"
                    placeholder="請輸入收件人電話..."
                  />
                </div>
              </div>
              <label for="receiverAddressDelivery">
                {{ code === '1657785018' ? '新家' : '收件人' }}地址：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row mb-2">
                <cascader-cites
                  :cityId="state.useReceiverCityId"
                  :cityareaId="state.useReceiverCityareaId"
                  @change="setRecevierProfileSelect"
                ></cascader-cites>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useReceiverAddress"
                    type="text"
                    class="form-control"
                    id="receiverAddressDelivery"
                    :placeholder="`請輸入${code === '1657785018' ? '新家' : '收件人'}地址...`"
                  />
                </div>
              </div>
            </div>
            <div class="form-group container">
              <label for="deliverComment">備註：</label>
              <div class="row row-cols-1">
                <div class="col">
                  <textarea
                    v-model="state.useComment"
                    class="form-control"
                    id="deliverComment"
                    rows="3"
                    placeholder="請輸入備註事項..."
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="form-group container">
              <div class="row text-right font-weight-bold" style="font-size: medium">
                <div class="col-12">
                  <span>小計：</span>
                  <span class="text-main">{{ cartQuantity }}</span>
                  <span>{{ $t("ORDER.TOTAL.GOODS_UNIT") }}</span>
                  <span style="margin: 10px; color: darkgray">｜</span>
                  <span>{{ $t("ORDER.TOTAL.MONEY") }}：</span>
                  <span class="text-main">$ {{ formatMoney(cartTotal) }}</span>
                  <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
                </div>
                <div class="col-12">
                  <span>{{ state.shippingTypeName }}運費</span>
                  <span class="text-main">${{ state.shippingAmount }}</span>
                  <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
                  <span
                    style="color: #d3d3d3; font-width: normal"
                    v-if="state.shippingFreeDescription > 0"
                  >
                    <br />
                    (滿{{ state.shippingFreeDescription }}元免運費)
                  </span>
                </div>
                <div class="col-12">
                  <span>總計：</span>
                  <span class="text-main">
                    $
                    {{
                    formatMoney(
                    parseInt(cartTotal, 10) +
                    parseInt(state.shippingAmount, 10)
                    )
                    }}
                  </span>
                  <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav class="checkout-menu">
      <div class="row no-gutters justify-content-center w-100 fixed-bottom bg-light">
        <div class="col px-3">
          <a
            href="/shop/carts"
            class="btn bg-dark btn-block shadow text-white my-2"
            id="optionClose"
          >{{ $t("CHECKOUT.BUTTON.BACK") }}</a>
        </div>
        <div class="col px-3">
          <button
            type="button"
            class="btn btn-block btn-main shadow text-white my-2"
            @click="onCheckout"
          >{{ $t("CART.BUTTON.NEXT") }}</button>
        </div>
      </div>
    </nav>
  </div>
</template>
<style>
.el-cascader {
  width: 100%;
}
.check-way .check-way-list .list-group-item {
  cursor: pointer;
}
.menu-detail .item-option fieldset:last-child {
  margin-bottom: 6rem;
}
</style>
<script>
import CascaderCites from "@/components/CascaderCites";
import { computed, reactive, watch, inject, ref } from "vue";
//Add By Bryant
//import { ref } from "vue";
//=====
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { format } from "date-fns-tz";
import {
  formatMoney,
  getPickDateTimeObject,
  getPickTimeOptions,
} from "@/utils/tools";
import Swal from "sweetalert2";
import storage from "@u/storage";
import TimeSelect from "@/components/TimeSelect.vue";
import moment from "moment";

import Schema from "async-validator";
storage.config = { type: "session" };

export default {
  name: "CheckoutView",
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  components: {
    CascaderCites,
    //    TimeSelect,
  },
  setup(props, { emit }) {
    //Add By Bryant
    //const value1 = ref('');

    //=====
    const shop_name = inject("shop_name");
    const shop_open_news = inject("shop_open_news"); // 營業時間
    const shop_closed = inject("shop_closed");
    const shop_shipping = inject("shop_shipping");
    // console.log(shop_shipping);
    const checkout_info = storage.getItem("checkout_info") || {};
    const address_info = storage.getItem("address_info") || {};
    // console.log(checkout_info);
    const code = ref(storage.getItem('liff-id').split('-')[0])
    // console.log(code.value)
    const getShopShippingDefault = () => {
      let shopShipping = ["instore", "delivery"];
      let arr = [];
      shopShipping.forEach((item) => {
        if (shop_shipping.value[item].status === 1) {
          arr.push(item);
        }
      });
      return arr[0];
    };
    const state = reactive({
      useCheckoutType:
        "type" in checkout_info ? checkout_info.type : getShopShippingDefault(),
      usePickDate: null,
      usePickTime: 0,
      useComment: null,
      useBuyerAddress: null,
      useBuyerCityId: 0,
      useBuyerCityareaId: 0,
      useBuyerName: null,
      useBuyerPhone: null,
      useBuyerInvoiceType: 1,
      useBuyerInvoiceCarrierId: null,
      useBuyerInvoiceNumber: null,
      useBuyerInvoiceNpoBan: null,
      useReceiverName: null,
      useReceiverPhone: null,
      useReceiverAddress: null,
      useReceiverCityId: 0,
      useReceiverCityareaId: 0,
      tabInStore: true,
      tabDelivery: false,
      tabInStoreActive: "active",
      tabDeliveryActive: null,
      businessDisabled: [],
      optionsPickTime: [],
      optionsPickDateObjects: [],
      shippingAmount: 0,
      shippingTypeName: null,
      shippingDescription: null,
      shippingFreeDescription: null,
      showReceiver: false,
    });
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    const onGoBack = () => {
      if (storage.getItem("shop_category_id") > 0)
        router.push("/shop/category/" + storage.getItem("shop_category_id"));
      else router.push("/shop/category");
    };

    //無資料就回到產品列表頁面
    store.dispatch("carts/isEmptyCarts", { kind: 2 });
    const isEmptyCarts = computed(() => {
      return store.getters["carts/isEmptyCarts"];
    });

    // //預設資料
    // if (shop_shipping.value.instore.status === 1) {
    //   state.useCheckoutType = "instore";
    // } else if (shop_shipping.value.delivery.status === 1) {
    //   state.useCheckoutType = "delivery";
    // }

    // 數量
    const cartQuantity = computed(() => store.getters["carts/cartQuantity"]);
    // 總金額
    const cartTotal = computed(() => store.getters["carts/cartTotal"]);
    // 判斷是否為低溫配送
    const cartShippingIsCold = computed(
      () => store.getters["carts/cartShippingIsCold"]
    );
    const soldOutProduct = computed(() => store.getters["carts/soldOutProduct"]);
    const errorProduct = computed(() => store.getters["carts/errorProduct"]);

    // 取貨方式設定
    const __saveShippingAmount = () => {
      const shipping_setting = shop_shipping.value;
      if (
        Object.prototype.hasOwnProperty.call(
          shipping_setting,
          state.useCheckoutType
        )
      ) {
        let my_shipping_setting = shipping_setting[state.useCheckoutType];
        if (state.useCheckoutType === "delivery") {
          my_shipping_setting =
            cartShippingIsCold.value !== undefined
              ? my_shipping_setting.cold
              : my_shipping_setting.amb;
        }
        state.shippingAmount =
          cartTotal.value >= my_shipping_setting.free
            ? 0
            : my_shipping_setting.fee;

        //Modify By Bryant (2022/04/28)
        //state.shippingDescription = my_shipping_setting.description;
        if (my_shipping_setting.description) {
          state.shippingDescription = my_shipping_setting.description.replace(
            /\n/g,
            "<br />\n"
          );
        }

        //console.log('state.shippingDescription: ', state.shippingDescription);
        //=============================

        state.shippingFreeDescription = my_shipping_setting.free;
        state.shippingTypeName = my_shipping_setting.name;
      } else {
        state.shippingAmount = 0;
        state.shippingDescription = null;
        state.shippingFreeDescription = null;
        state.shippingTypeName = null;
      }
    };

    const __pickDateObjects = () => {
      // console.log(shop_open_news);
      let afterDays = 0;
      let currentDay = moment().format("DD");
      let daysInMonth = moment().daysInMonth();
      let deliveryColdType =
        cartShippingIsCold.value !== undefined ? "cold" : "amb";
      if (state.useCheckoutType === "instore") {
        afterDays =
          shop_shipping.value[state.useCheckoutType].pre_order_after_days;
      } else if (state.useCheckoutType === "delivery") {
        afterDays =
          shop_shipping.value[state.useCheckoutType][deliveryColdType]
            .pre_order_after_days;
      }
      for (let i = 1; i <= daysInMonth - currentDay - afterDays; i++) {
        let afterDayWeek = moment().add(i + afterDays, "days").weekday();
        if (afterDayWeek === 0) afterDayWeek = 7;
        for(let a in shop_open_news.value){
          if(shop_open_news.value[a].day === afterDayWeek && shop_open_news.value[a].status === 1){
            state.usePickDate = moment().add(i + afterDays, "days").toDate()
            return
          }
        }
      }
      // let weeks = [];
      // [...Array(10)].forEach((e, i) => {
      //   weeks.push({
      //     day: i,
      //     hours: [{ open: "00:00", close: "23:59" }],
      //     status: 1,
      //   });
      // });
      // if (state.useCheckoutType === "instore") {
      //   weeks = shop_open_news.value.filter(function (week) {
      //     return week.status === 1;
      //   });
      // }
      // state.optionsPickDateObjects = getPickDateTimeObject(
      //   weeks,
      //   shop_shipping.value[state.useCheckoutType].pre_order_after_days,
      //   shop_closed.value.status,
      //   shop_closed.value.min
      // );
      // console.log(state.optionsPickDateObjects)
    };

    //一開始就要取資料
 
    store.dispatch("carts/getCartsRefresh", { kind: 2 })
    .then(() => {
      __saveShippingAmount();
      if (store.getters["carts/errorProduct"]) {
        setErrorProduct(false);
        return
      }
      if (cartList.value.length === 0) {
        onGoBack();
      }
    }); //取購物車

    const disabledDate = (time) => {
      //console.log('Date.now(): ', Date.now())
      //return time.getTime() < Date.now() + 86400000 * 2
      //return {
      //  from: new Date(Date.now() + 86400000 * 2),
      //  to: new Date(Date.now() + 86400000 * 28)
      //}
      let withinDays = 0;
      let afterDays = 0;
      let deliveryColdType =
        cartShippingIsCold.value !== undefined ? "cold" : "amb";
      if (state.useCheckoutType === "instore") {
        withinDays =
          shop_shipping.value[state.useCheckoutType].pre_order_within_days;
        afterDays =
          shop_shipping.value[state.useCheckoutType].pre_order_after_days;
      } else if (state.useCheckoutType === "delivery") {
        withinDays =
          shop_shipping.value[state.useCheckoutType][deliveryColdType]
            .pre_order_within_days;
        afterDays =
          shop_shipping.value[state.useCheckoutType][deliveryColdType]
            .pre_order_after_days;
      }
      // time為日期套件中單月份的全部日期(包含上個月月底和下個月月初的其中幾天)
      let getDay = time.getDay();
      if (getDay === 0) getDay = 7;
      let openDay = null;
      // 判斷店家是否有營業 true為有營業
      const checkoutOpenDay = (getDay) => {
        for (let i in shop_open_news.value) {
          if (
            shop_open_news.value[i].day === getDay &&
            shop_open_news.value[i].status === 1
          ) {
            return true;
          }
        }
      };
      checkoutOpenDay(getDay) ? (openDay = time.getTime()) : null;
      if (
        time.getTime() > moment().add(withinDays, "days") ||
        time.getTime() < moment().add(afterDays, "days") ||
        time.getTime() != openDay
      ) {
        return true;
      } else {
        return false;
      }
    };

    // const pickerOptions = {
    //   disabledDate: (time) => {
    //     if (
    //       time.getTime() < Date.now() + 86400000 * 2 ||
    //       time.getTime() > Date.now() + 86400000 * 28
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    // };

    // 如果為null會報錯
    store.dispatch("member/getProfile").then(() => {
      store.dispatch("member/getDefaultAddress");
      state.useReceiverName =
        "receiver_address" in checkout_info
          ? checkout_info.receiver_name
          : "";
      state.useReceiverPhone =
        "receiver_phone" in checkout_info
          ? checkout_info.receiver_phone
          : "";
      state.useReceiverCityId =
        "receiver_city_id" in checkout_info
          ? checkout_info.receiver_city_id
          : "";
      state.useReceiverCityareaId =
        "receiver_cityarea_id" in checkout_info
          ? checkout_info.receiver_cityarea_id
          : "";
      state.useReceiverAddress =
        "receiver_address" in checkout_info
          ? checkout_info.receiver_address
          : "";
      state.useBuyerName =
        "buyer_name" in checkout_info
          ? checkout_info.buyer_name
          : profile.value.name;
      state.useBuyerPhone =
        "buyer_phone" in checkout_info
          ? checkout_info.buyer_phone
          : profile.value.mobile_phone;
      state.useBuyerCityId =
        "buyer_city_id" in address_info
          ? address_info.buyer_city_id
          : defaultAddress.value.city_id;
      state.useBuyerCityareaId =
        "buyer_cityarea_id" in address_info
          ? address_info.buyer_cityarea_id
          : defaultAddress.value.cityarea_id;
      state.useBuyerAddress =
        "buyer_address" in address_info
          ? address_info.buyer_address
          : defaultAddress.value.address;
      state.useBuyerInvoiceType =
        "invoice" in checkout_info ? checkout_info.invoice.type : 1;
      state.useBuyerInvoiceCarrierId =
        "invoice" in checkout_info
          ? checkout_info.invoice.carrier_id
          : profile.value.invoice_carrier;
      state.useBuyerInvoiceNpoBan =
        "invoice" in checkout_info
          ? checkout_info.invoice.npo_ban
          : profile.value.npo_ban;
      state.useBuyerInvoiceNumber =
        "invoice" in checkout_info
          ? checkout_info.invoice.vat_number
          : profile.value.vat_number;
    });
    // if ("type" in checkout_info) state.useCheckoutType = checkout_info.type;
    if ("pick_date" in checkout_info)
      state.usePickDate = checkout_info.pick_date;
    //state.usePickDate = state.usePickDate + 864000000 * 3;
    //console.log('YYYYYYY: ', state.usePickDate)
    if ("pick_time" in checkout_info)
      state.usePickTime = checkout_info.pick_time;
    if ("comment" in checkout_info) state.useComment = checkout_info.comment;

    //computed: getter
    const isLoading = computed(() => store.getters["carts/isLoading"]);
    const myIsLoading = computed(() => {
      emit("onLoading", isLoading.value);
      return props.isLoading;
    });
    const cartList = computed(() => store.getters["carts/cartList"]);
    const profile = computed(() => store.getters["member/profile"]);
    const defaultAddress = computed(
      () => store.getters["member/defaultAddress"]
    );

    //methods
    const handleTabClick = (useCheckoutType) => {
      state.useCheckoutType = useCheckoutType;
      // const key = state.useCheckoutType;
      // const tab = { instore: false, delivery: false };
      // const tabActive = { instore: "", delivery: "" };
      // tab[key] = true;
      // tabActive[key] = "active";
      // state.tabInStore = tab.instore;
      // state.tabDelivery = tab.delivery;
      // state.tabInStoreActive = tabActive.instore;
      // state.tabDeliveryActive = tabActive.delivery;
      __saveShippingAmount();
      __pickDateObjects();
    };
    const setErrorProduct = (val) => {
      store.commit("carts/setErrorProduct", val);
    };
    const handleHourDisabled = (key, type = "key") => {
      if (type == "class") return state.businessDisabled[key] ? "disabled" : "";
      else return state.businessDisabled[key];
    };
    const setProfileSelect = (value) => {
      state.useBuyerCityId = value[0];
      state.useBuyerCityareaId = value[1];
    };
    const setRecevierProfileSelect = (value) => {
      // console.log(value)
      state.useReceiverCityId = value[0];
      state.useReceiverCityareaId = value[1];
    };
    const onCheckout = () => {
      if (validateCheckout() === false) return false;
      store
        .dispatch("carts/saveAddressInfo", {
          buyer_city_id: state.useBuyerCityId,
          buyer_cityarea_id: state.useBuyerCityareaId,
          buyer_address: state.useBuyerAddress,
        })
      store
        .dispatch("carts/saveCheckoutInfo", {
          type: state.useCheckoutType,
          pick_date: state.usePickDate,
          pick_time: state.usePickTime,
          comment: state.useComment,
          receiver_city_id: state.useReceiverCityId,
          receiver_cityarea_id: state.useReceiverCityareaId,
          receiver_address: state.useReceiverAddress,
          receiver_name: state.useReceiverName,
          receiver_phone: state.useReceiverPhone,
          buyer_name: state.useBuyerName,
          buyer_phone: state.useBuyerPhone,
          buyer_city_id: state.useBuyerCityId,
          buyer_cityarea_id: state.useBuyerCityareaId,
          buyer_address: state.useBuyerAddress,
          invoice: {
            type: state.useBuyerInvoiceType,
            vat_number: state.useBuyerInvoiceNumber,
            carrier_id: state.useBuyerInvoiceCarrierId,
            npo_ban: state.useBuyerInvoiceNpoBan,
          },
        })
        .then(() => {
          router.push("/shop/checkout_confirm");
        })
        .catch(() => {
          onAlert("error");
        });
    };
    const validateCheckout = () => {
      let error_msg = "";
      let schema = {};
      schema["usePickDate"] = {
        required: true,
        message: "請選擇「取/到貨日期」",
      };
      schema["usePickTime"] = {
        required: true,
        message: "請選擇「取/到貨時間」",
      };
      if (state.useCheckoutType === "delivery") {
        schema["useBuyerName"] = {
          required: true,
          type: "string",
          message: "訂購人：請填入資料，不可空白",
        };
        schema["useBuyerPhone"] = {
          required: true,
          type: "string",
          min: 8,
          max: 10,
          pattern: /^[0-9]+$/,
          message: "訂購人電話：格式不符(8-10碼數字）",
        };
        schema["useBuyerCityId"] = {
          required: true,
          type: "number",
          message: "訂購人城市：請選擇城市",
        };
        schema["useBuyerCityareaId"] = {
          required: true,
          type: "number",
          message: "訂購人區域：請選擇區域",
        };
        schema["useBuyerAddress"] = {
          required: true,
          type: "string",
          message: `${code.value === '1657785018' ? '舊家' : '訂購人'}地址：請填入資料，不可空白`,
        };
        if (state.showReceiver === true) {
          schema["useReceiverName"] = {
            required: true,
            type: "string",
            message: "收件人：請填入資料，不可空白",
          };
          schema["useReceiverPhone"] = {
            required: true,
            type: "string",
            min: 8,
            max: 10,
            pattern: /^[0-9]+$/,
            message: "收件人電話：格式不符(8-10碼數字）",
          };
          schema["useReceiverCityId"] = {
            required: true,
            type: "number",
            message: "收件人城市：請選擇城市",
          };
          schema["useReceiverCityareaId"] = {
            required: true,
            type: "number",
            message: "收件人區域：請選擇區域",
          };
          schema["useReceiverAddress"] = {
            required: true,
            type: "string",
            message: `${code.value === '1657785018' ? '新家' : '收件人'}地址：請填入資料，不可空白`,
          };
        } else {
          state.useReceiverCityareaId = state.useBuyerCityareaId;
          state.useReceiverCityId = state.useBuyerCityId;
          state.useReceiverAddress = state.useBuyerAddress;
          state.useReceiverPhone = state.useBuyerPhone;
          state.useReceiverName = state.useBuyerName;
        }
      } else {
        schema["useBuyerName"] = {
          required: true,
          type: "string",
          message: "取貨人姓名：請填入資料，不可空白",
        };
        schema["useBuyerPhone"] = {
          required: true,
          type: "string",
          min: 8,
          max: 10,
          pattern: /^[0-9]+$/,
          message: "取貨人電話：格式不符(8-10碼數字）",
        };
      }
      const validator = new Schema(schema);
      validator.validate(state, (errors, fields) => {
        if (errors) {
          errors.forEach((error) => (error_msg += error.message + "<br />"));
        }
      });
      if (error_msg) {
        onAlert("error", error_msg);
        return false;
      } else {
        return true;
      }
    };
    const onAlert = (type, error_msg = null) => {
      let title = type === "success" ? t("ALERT.SUCCESS") : t("ALERT.ERROR");
      error_msg =
        type !== "error"
          ? ""
          : "<br /><span style='color:red'>" + error_msg + "</span>";
      Swal.fire({
        title: title,
        html: "確認結帳失敗" + error_msg,
        icon: type,
        showConfirmButton: true,
        confirmButtonColor: "#fd7e14",
        confirmButtonText: "關閉",
        timer: 5000,
      });
    };

    // handleTabClick();

    __pickDateObjects();
    // state.usePickDate = Object.keys(state.optionsPickDateObjects)[0];
    //Add By Bryant
    //console.log('TTTTT: ', state.usePickDate);
    //state.usePickDate = state.usePickDate + 86400000;
    //console.log('VVVVV: ', state.usePickDate);
    //    var Modify_PickTime = new Date(state.usePickDate);
    //console.log('pp: ', pp);
    //state.usePickDate = pp + 864000000;
    //state.usePickDate = pp;
    //    Modify_PickTime = new Date(Modify_PickTime.getTime() + 86400000 * 3);
    //state.usePickDate = Modify_PickTime.toDateString();
    //    state.usePickDate = Modify_PickTime.getUTCFullYear() + '-' + (Modify_PickTime.getUTCMonth() + 1) + '-' + Modify_PickTime.getUTCDate();

    // var ModifyDate = new Date(new Date().getTime() + 86400000 * 3);
    //ModifyDate = ModifyDate + 86400000;
    // state.usePickDate = ModifyDate;
    //=====

    return {
      disabledDate,
      // pickerOptions,
      //=====
      shop_name,
      shop_shipping,
      state,
      isEmptyCarts,
      soldOutProduct,
      errorProduct,
      myIsLoading,
      cartQuantity,
      cartTotal,
      defaultAddress,
      storage,
      code,
      onGoBack,
      formatMoney,
      handleTabClick,
      handleHourDisabled,
      setProfileSelect,
      setRecevierProfileSelect,
      onCheckout,
      format,
      onAlert,
      setErrorProduct,
    };
  },
  watch: {
    soldOutProduct: function (newVal, oldVal) {
      // console.log("soldOutProduct", newVal);
      if (newVal.length > 0) {
        Swal.fire({
          title: "商品庫存不足或售完",
          html: newVal.join("<br />"),
          icon: "error",
          showConfirmButton: false,
          timer: 5000,
        });
        this.$router.push("/shop/carts");
      }
    },
    errorProduct: function (newVal, oldVal) {
      // console.log("errorProduct", newVal);
      if (newVal) {
        Swal.fire({
          title: "商品異常",
          icon: "error",
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonColor: "#fd7e14",
          confirmButtonText: "確認"
        }).then(() => {
          this.$router.push("/shop/carts");
        });
      }
    },
    isEmptyCarts: function (newVal, oldVal) {
      this.onGoBack();
    },
  },
};
</script>
