<template>
  <div class="time-select">
    <button class="btn btn-block btn-select" @click="isShow = true">
      {{ modelValue ? modelValue : "請選擇日期" }}
    </button>
    <el-drawer
      title="請選擇時間"
      v-model="isShow"
      direction="btt"
      :with-header="false"
      custom-class="date-time-panel"
      @open="onOpen"
    >
      <div class="modal-dialog container">
        <div class="modal-content">
          <p class="text-center text-secondary mt-3 mb-1">請選擇時間</p>
          <div
            class="row py-2"
            style="overflow-y: auto; max-height: calc(100vh - 143px)"
            :class="type"
          >
            <template v-for="(time, key) in options" :key="key">
              <div class="col">
                <div class="col">
                  <label
                    class="btn btn-block btn-outline-secondary"
                    :class="{
                      active:
                        currentValue === key || currentValue === time.value,
                    }"
                  >
                    <input
                      type="radio"
                      name="options"
                      :value="type === 'date-panel' ? key : time.value"
                      v-model="currentValue"
                    />
                    {{ type === "date-panel" ? key : time.text }}
                  </label>
                </div>
              </div>
            </template>
          </div>
          <el-button
            type="primary"
            class="btn btn-block btn-primary text-white mb-3"
            @click="btnClick"
            >選擇</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { computed, ref } from "vue";
export default {
  props: {
    options: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default: "date-panel",
    },
    modelValue: {
      type: String,
    },
    linkFun: Function,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isShow = ref(false);
    const currentValue = ref(null);

    const btnClick = () => {
      isShow.value = false;
      if (props.linkFun) props.linkFun(currentValue.value);
      emit("update:modelValue", currentValue.value);
    };

    const onOpen = () => {
      currentValue.value = props.modelValue;
    };

    return {
      isShow,
      currentValue,
      btnClick,
      onOpen,
    };
  },
};
</script>

<style scoped lang="scss">
.time-select::v-deep .el-overlay {
  .el-drawer {
    background-color: transparent;
    box-shadow: none;
  }
}
</style>
